<template>
    <div>
        <StorageProductsModal v-if="modal.open" :saving="modal.saving" :product="modal.product" @close="modal.open = false" @accept="updateQuantities"></StorageProductsModal>
        <div>
            <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
                <li class="flex">
                    <div class="flex items-center">
                    <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Lista produktów</div>
                    </div>
                </li>
                </ol>
            </nav>
            <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

            <!-- <label for="search" class="block text-sm font-medium text-gray-700 mt-4">Search</label> -->

            <!-- SEARCHER -->
            <div class="min-w-0 flex-1 xl:col-span-6">
                <div class="flex items-center justify-between">
                    <div class="w-2/3 sm:w-1/3 flex">
                        <label for="search" class="sr-only">Szukaj</label>
                        <div class="relative">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                        </div>
                        <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                    </div>
                </div>
                <div class="mt-4" v-if="searcher.found.length > 0">
                    <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                        Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                        <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                        </button>
                    </span>           
                </div>
            </div>

            <!-- <Spinner v-if="loading"></Spinner> -->
            <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Produkt</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">EAN / SKU</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Atrybuty</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Warianty</th>
                                        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span class="sr-only">Edycja</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="product in searcher.found.length > 0 ? searcher.found : products" :key="product.id">
                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                            <div class="flex items-center">
                                            <div class="h-10 w-10 flex-shrink-0">
                                                <img class="h-10 w-10 rounded-md object-contain" :src="product.image.url.length>0 ? product.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${product.id}`}`" :alt="product.name" />
                                            </div>
                                            <div class="ml-4">
                                                <div class="font-medium text-gray-900">{{product.name}} <span class="transition ease-in-out duration-300 text-xs text-blue-300 hover:text-blue-500 select-none cursor-pointer" @click.prevent="copyToClipboard(product.id)">Kopiuj ID</span></div>
                                                <div class="text-gray-500"><span class="text-xs" v-if="product.category.name.length>0">{{product.category.name}} <span class="text-xs opacity-60">(ID kategorii:{{product.category.id}})</span></span><span class="text-xs" v-if="product.category.name.length===0">Brak kategorii</span></div>
                                            </div>
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                            <div class="text-gray-500"><span class="text-xs" v-if="product.ean.length>0"><span class="text-gray-700 font-medium">EAN: </span>{{product.ean}}</span><span class="text-xs font-medium opacity-60" v-if="product.ean.length===0">Brak EAN</span><span class="transition ease-in-out duration-300 ml-2 text-xs text-blue-300 hover:text-blue-600 select-none cursor-pointer" v-if="product.ean.length>0" @click.prevent="copyToClipboard(product.ean)">Kopiuj</span></div>
                                            <div class="text-gray-500"><span class="text-xs" v-if="product.sku.length>0"><span class="text-gray-700 font-medium">SKU: </span>{{product.sku}}</span><span class="text-xs font-medium opacity-60" v-if="product.sku.length===0">Brak SKU</span><span class="transition ease-in-out duration-300 ml-2 text-xs text-blue-300 hover:text-blue-600 select-none cursor-pointer" v-if="product.sku.length>0" @click.prevent="copyToClipboard(product.sku)">Kopiuj</span></div>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                            <div>
                                                <span v-for="value in product.attribs.list.slice(0, 1)" :key="value.id" @click="showModal(product)"  class="transition ease-in-out duration-300 select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-600 cursor-pointer hover:bg-blue-100 hover:text-blue-400"> {{value.parent.name}}: {{value.name}} </span>
                                                <span class="transition ease-in-out duration-300 text-xs px-2.5 py-0.5 text-blue-300 hover:text-blue-500 cursor-pointer" v-if="product.attribs.list.length-1 > 0" @click="showModal(product)">+ {{product.attribs.list.length-1}} więcej</span>
                                                <span class="text-xs" v-if="product.attribs.list.length === 0">Brak atrybutów</span>
                                            </div>
                                        </td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700 select-none">
                                            <div>
                                                <span class="transition ease-in-out duration-300 select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-50 text-blue-400" v-if="Object.keys(product.variants).length > 0">{{Object.keys(product.variants).length}} {{Object.keys(product.variants).length === 1 ? "wariant" : Object.keys(product.variants).length > 1 && Object.keys(product.variants).length < 5 ? "warianty" : "wariantów"}}</span>
                                                <span class="text-xs" v-if="Object.keys(product.variants).length === 0">Brak wariantów</span>
                                            </div>
                                        </td>
                                        <td  class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <button @click="modal.open = true, modal.product=product" class="transition ease-in-out duration-300 -ml-px relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">Zarządzaj</button>
                                            <span class="sr-only">, {{ product.name }}</span>      
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <EmptyState class="mt-2 mb-2" v-if="products.length === 0"></EmptyState>
            <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
                <button type="button" @click.prevent="loadMoreProducts" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
            </div>    
        </div>
    </div>
</template>

<script>
import { HomeIcon } from '@heroicons/vue/solid'
import {db} from "@/firebase/gfbconf.js";
import { SearchIcon, PlusSmIcon, XIcon  } from '@heroicons/vue/outline';
import EmptyState from '../components/EmptyState.vue'
import StorageProductsModal from '../components/StorageProductsModal.vue'
import axios from 'axios';

 export default {
    data() {
        return {
            loading: false,
            searcher: {
                found: [],
                locked: false,
                phrase: "",
                lastCall: null
            },
            modal: {
                open: false,
                product: null,
                saving: false
            },
            products: [],
            queryLimit: 25,
            dbListener: null,
            lastLoaded: null,
            queryLimitReached: false
        }
    },
    components: {
        EmptyState,
        StorageProductsModal,
        HomeIcon,
        SearchIcon, PlusSmIcon, XIcon,
    },
    methods: {
        async updateQuantities(data){
            this.modal.saving = true
            const product = await db.collection("Products").doc(data.product);

            for(let variant of data.variants){

                const query = `variants.${variant.id}.quantity`

                await product.update({
                    [query] : variant.quantity
                })
            }
            this.modal.saving = false
            this.modal.open = false
        },
        resetSearch()
        {
            this.products = [];
            this.searcher.found = [];
            this.searcher.lastCall = null;
            this.searcher.phrase = "";
            this.dbListener = db.collection("Products").orderBy("meta.createdDate", "desc").limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.queryLoading = true;
                this.products = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    this.products.push(doc.data())
                    this.lastLoaded = doc.data().meta.createdDate;
                });
                this.queryLoading = false;
            });
        },
        async search()
        {
            if(this.searcher.phrase.length > 0)
            {
                this.searcher.locked = true;
                const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                    index: "jan-art-products",
                    collection: "Products",
                    phrase: this.searcher.phrase,
                    searchFields: ['names','models','skus','eans','id','categories','manufacturers','variantsIds','baselinkerIds']
                })
                if(res.data.success)
                {
                    this.searcher.found = res.data.found;
                    if(res.data.found.length > 0)
                    {
                        this.searcher.lastCall = res.data.data;
                        this.dbListener();
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wyszukiwanie zakończone!",
                            subheader: `Znaleziono ${res.data.found.length} produktów.`,
                            success: true
                        });

                    }else{
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Nie znaleziono wyników!",
                            subheader: `Spróbuj wyszukać inną frazę.`,
                            success: false
                        });
                    }

                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Wyszukiwanie zakończone niepowodzeniem!",
                        subheader: `Coś poszło nie tak...`,
                        success: false
                    });
                }
                setTimeout(()=>
                {
                    // this.searcher.phrase = "";
                    this.searcher.locked = false;
                }, 3000)
            }
            else
            {
                return;
            }
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 10;
            this.dbListener = db.collection("Products").orderBy("meta.createdDate", "desc").limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.products = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    this.products.push(doc.data());
                    this.lastLoaded = doc.data().meta.createdDate;
                });
            });
        },
        loadMoreProducts()
        {
            this.dbListener();
            this.continueListening();      
        },
        copyToClipboard(id)
            {
                navigator.clipboard.writeText(id).then(()=>
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Skopiowano do schowka!",
                        subheader: ``,
                        success: true
                    }); 
                })

            },
    },
    async created()
    {
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.storage)
        {
            this.$router.push("/hub")
        }
        this.dbListener = db.collection("Products").orderBy("meta.createdDate", "desc").limit(this.queryLimit)
        .onSnapshot((querySnapshot) => {
            this.products = [];
            if(querySnapshot.docs.length<this.queryLimit)
            {
                this.queryLimitReached = true;
            }
            else
            {
                this.queryLimitReached = false;
            }
            querySnapshot.forEach((doc) => {
                this.products.push(doc.data());
                this.lastLoaded = doc.data().meta.createdDate;
            });
        });
    },
    beforeUnmount() {
        if(this.dbListener !== null){
            this.dbListener();
        }
    },
 }
</script>