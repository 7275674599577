<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-600 to-purple-600 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">Zarządzaj {{ product.name }} </DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Zarządzaj produktem.</p>
                    </div>
                  </div>
                  
                  <div v-if="Object.keys(this.product.variants).length !== 0">
                    <label class="block text-sm font-medium text-gray-500 px-6 mt-6 mb-2 select-none">Podstawowe informacje</label>
                    <div class="relative">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-center">
                            <span class="bg-white px-2 text-sm text-gray-500"></span>
                        </div>
                    </div>
                  </div>
                  
                  <div class="px-6">
                    <div v-if="Object.keys(this.product.variants).length !== 0">
                      <!-- <div v-for="variant in product.variants" :key="variant.id"> -->
                        <ul v-for="variant in product.variants" :key="variant.id" role="list" class="text-sm font-medium text-gray-500">
                          <li class="flex space-x-6 pt-6 pb-3 break-all">
                            <img class="h-10 w-10" :src="variant.image.url.length>0 ? variant.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${product.id}`}`" :alt="variant.name" />
                            <div class="flex-auto space-y-1">
                              <h3 class="text-gray-900">
                                <a>{{ variant.name }}</a>
                              </h3>
                              <div class="flex justify-between">
                              <span class="select-none">Ilość: <span class="font-bold">{{ variant.quantity }}</span> sztuk.</span>
                                <div>
                                  <PrinterIcon @click="switchPrint(variant)" class="h-5 w-5 cursor-pointer"></PrinterIcon>
                              </div>                       
                              </div>
                            </div>
                            
                          </li>
                          <div :class="!variant.print ? 'hidden' : ''" class="flex justify-between">
                            <input min="1" max="99" maxlength="2" oninput="this.value=this.value.slice(0,this.maxLength||1/1);this.value=(this.value   < 1) ? (1/1) : this.value;" v-model="printQuantity" type="number" placeholder="Podaj ilość etykiet..." class="w-full block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                            <button  @click.prevent="printVariant(variant)" class="ml-2 rounded-md border border-gray-600 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Drukuj</button>
                          </div>
                          
                          
                          <div :class="variant.print ? 'hidden' : ''" class="">
                            <input v-model="variant.quantity" @change="validateQuantity" onkeypress='return event.charCode >= 48 && event.charCode <= 57' ref="newQuantities" type="text" name="quantity" id="quantity" placeholder="Edytuj ilość..." class="w-full block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                          </div>
                          <transition  enter-active-class="transition ease-out duration-100" enter-from-class="transform -translate-y-5" enter-to-class="" leave-active-class="transition ease-in duration-200" leave-from-class="" leave-to-class="transform -translate-y-5">
                            <div v-if="variant.buffer && Object.values(variant.buffer).length > 0" class="relative my-3" >
                              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                <div class="w-full border-t border-gray-300" />
                              </div>
                              <div class="relative flex justify-center">
                                <span class="bg-white px-2 text-gray-400">
                                  <ChevronDownIcon v-if="!variant.info" @click="openInfo(variant)" v-tooltip="'Pokaż zamówienia'" class="h-6 w-6 text-gray-400 cursor-pointer" aria-hidden="true" />
                                  <ChevronUpIcon v-if="variant.info" @click="openInfo(variant)" v-tooltip="'Ukryj zamówienia'" class="h-6 w-6 text-gray-400 cursor-pointer" aria-hidden="true" />
                                  <!-- <ChevronUpIcon v-if="!this.toggleDescription[index].open" class="h-6 w-6 text-gray-400" aria-hidden="true" /> -->
                                </span>
                              </div>
                            </div>
                            </transition> 
                            <div v-if="variant.info" class="flex justify-items-center">
                              <div v-for="buffer in createCommentList(variant)" :key="buffer.id">
                               <ul v-for="buff in createBufferList(variant)" :key="buff.id" role="list" class="text-sm font-medium text-gray-500">
                                <div class="flex justify-center">
                                  <span class="text-gray-600 ">ID : </span>
                                 <span>  {{ buffer }} - {{ buff }}szt.</span>
                                 </div>
                               </ul>
                              </div>  
                            </div>
                        </ul>
                    </div>
                    <EmptyState v-else class="mt-4" />
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <div v-if="!saving">
                    <span class="text-xs text-gray-500" v-if="loading">Zapisywanie..</span>
                    <button @click.prevent="emitClose" v-if="!loading" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Zamknij</button>
                    <button @click.prevent="updateQuantities,getProducts()" v-if="!loading" class="ml-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Zapisz</button>
                  </div>
                  <div v-else>
                    <p>Zapisywanie ...</p>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import EmptyState from './EmptyState.vue'
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { QuestionMarkCircleIcon, XCircleIcon,PrinterIcon,ChevronDownIcon,ChevronUpIcon } from '@heroicons/vue/solid';
    import pdfMake from "pdfmake/build/pdfmake";
    import pdfFonts from "pdfmake/build/vfs_fonts";
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    export default {
        name: "ClientsModal",
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            EmptyState,
            QuestionMarkCircleIcon,
            XCircleIcon,PrinterIcon,ChevronDownIcon,ChevronUpIcon
        },
        props: ['product', 'saving'],
        emits:[ 'getProducts'],
        data: function () {
            return {
              loading: false,
              open: false,
              newVariantsData: [],
              printQuantity: null,
            }
        },
        methods: {
          openInfo(variant)
          {
            variant.info = !variant.info
          },
         createCommentList(order)
        {
            if(order.buffer){
            const products = Object.entries(order.buffer)
            let translatedListOfComments = [];
            products.forEach(element => {
                if(element[0])
                {
                  translatedListOfComments.push(element[0]);
                }
            })

            return translatedListOfComments}
            else{}        
        },
         createBufferList(order)
        {
            const products = Object.entries(order.buffer)
            let BufferQuantity = [];
            products.forEach(element => {
                if(element[0])
                {
                  BufferQuantity.push(element[1]);
                }
            })

            return BufferQuantity
        },
        
          switchPrint(variant)
          {
            variant.print = !variant.print
          },
          getProducts()
          {
            this.updateQuantities()
            this.$emit("getProducts")
          },
            callNotificationOnError(translatedCode)
            {
                this.emitClose();
                this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: translatedCode,
                success: false
                });
            },
            emitClose()
            {
                this.$emit("close");
                this.clearData();
            },
            emitAccept(data){
              this.$emit("accept", data)
                this.clearData();
            },
            clearData()
            {          
              
            },
            updateQuantities(){
              Object.values(this.product.variants).forEach((el, index) => {
                const newQuantity = parseInt(this.$refs.newQuantities[index].value);

                if(isNaN(newQuantity)) return

                this.newVariantsData.push({
                  id: el.id,
                  quantity: newQuantity,
                })
              })

              this.emitAccept({
                variants: this.newVariantsData,
                product: this.product.id
              })
            },
            printVariant(variant)
            {
               var docDefinition = {
                
                    pageSize: 'A6',
                    pageMargins: [10, 35, 10, 35 ],
                    content: [
                    ],

                };

                for(let i=0; i<this.printQuantity; i++)
                {
                  let template = [
                        {text: variant.name, fontSize: 14, bold:true, alignment: 'center'},
                        {text: '', margin: [0,4,0,4]},
                        {text: [{text: 'Model: ', bold:true}, variant.model.length > 0 ? variant.model : "BRAK DANYCH" ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: [{text: 'Producent: ', bold:true}, typeof variant.manufacturer === "string" ? (variant.manufacturer.length > 0 ? variant.manufacturer : "BRAK DANYCH") : (variant.manufacturer.name.length ? variant.manufacturer.name : "BRAK DANYCH") ], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: [{text: 'Kategoria: ', bold:true}, variant.category.name.length > 0 ? variant.category.name : "BRAK KATEGORII"], fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,8,0,8]},
                        {text: 'Wymiary: ', bold:true, fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {
                            table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {text: [{text: 'Długość: ', bold:true}, variant.dimensions.length > 0 ? `${variant.dimensions.length.toFixed(2)} ${variant.dimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                    {text: [{text: 'Wysokość: ', bold:true},variant.dimensions.height > 0 ? `${variant.dimensions.height.toFixed(2)} ${variant.dimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                ]
                            ]}
                        },
                        {
                            table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {text: [{text: 'Szerokość: ', bold:true}, variant.dimensions.width > 0 ? `${variant.dimensions.width.toFixed(2)} ${variant.dimensions.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                    {text: [{text: 'Waga: ', bold:true}, variant.weight.amount > 0 ? `${variant.weight.amount.toFixed(2)} ${variant.weight.unit}` : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                ]
                            ]}
                        },
                        {text: '', margin: [0,8,0,8]},
                        {text: 'Lokalizacja: ', bold:true, fontSize: 10, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text: variant.storage.location.length > 0 ? variant.storage.location : "BRAK DANYCH O LOKALIZACJI", fontSize: 12, alignment: 'center'},
                        {text: '', margin: [0,1,0,1]},
                        {text:  variant.storage.additionalInfo.length > 0 ? `(${variant.storage.additionalInfo})` : "BRAK DODATKOWYCH INFORMACJI", fontSize: 8, alignment: 'center'},
                        {text: '', margin: [0,10,0,10]},
                        {
                            table: {
                            widths: ['50%','50%'],
                            body: [
                                [
                                    {text: [{text: 'SKU: ', bold:true}, variant.sku.length > 0 ? variant.sku : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, true, false]},
                                    {text: [{text: 'EAN: ', bold:true}, variant.ean.length > 0 ? variant.ean : "BRAK DANYCH"], fontSize: 10, alignment: 'center', border: [false, false, false, false]},
                                ]
                            ]}
                        },
                        {text: '', margin: [0,10,0,10]},
                        { qr: variant.id, fit: '115', version: 3,  alignment: 'center' },
                    ]
                  docDefinition.content.push(...template)   
                }
        

                pdfMake.createPdf(docDefinition).download(`Etykieta_${variant.name.replace(/\s/g, '_')}.pdf`);
            },
            validateQuantity(){
              for(let input of this.$refs.newQuantities){
                if(input.value !== '')
                {
                  input.value = parseInt(input.value)

                  if(input.value > 999) input.value = 999
               
                  if(input.value < 0) input.value = 0
                }
              }
            }
            },
            computed:{

            },
    };
</script>